import React from 'react';
import './App.css';
import ModalImage from "react-modal-image";


import door0 from './doors/0.png'
import door1 from './doors/1.png'
import door2 from './doors/2.png'
import door3 from './doors/3.png'
import door4 from './doors/4.png'
import door5 from './doors/5.png'
import door6 from './doors/6.png'
import door7 from './doors/7.png'

import Adeliepinguin from './pingus/Adeliepinguin.png'
import Brillenpinguin from './pingus/Brillenpinguin.jpg'
import Dickschnabelpinguin from './pingus/Dickschnabelpinguin.jpg'
import Eselspinguin from './pingus/Eselspinguin.jpg'
import Felsenpinguin from './pingus/Felsenpinguin.jpg'
import Galapagospinguin from './pingus/Galapagospinguin.jpg'
import Gelbaugenpinguin from './pingus/Gelbaugenpinguin.jpg'
import Goldschopfpinguin from './pingus/Goldschopfpinguin.jpg'
import Haubepinguin from './pingus/Haubepinguin.jpg'
import Humboldtpinguin from './pingus/Humboldtpinguin.jpg'
import Kaiserpinguin from './pingus/Kaiserpinguin.jpg'
import Königspinguin from './pingus/Königspinguin.jpg'
import Kronenpinguin from './pingus/Kronenpinguin.jpg'
import Magellanpinguin from './pingus/Magellanpinguin.jpg'
import Snaresinselpinguin from './pingus/Snaresinselpinguin.jpg'
import Tristanpinguin from './pingus/Tristanpinguin.jpg'
import Zügelpinguin from './pingus/Zügelpinguin.jpg'
import Zwergpinguin from './pingus/Zwergpinguin.jpg'

import Analysispinguin from './pingus/Analysispinguin.png'
import Unicodepinguin from './pingus/Unicodepinguin.png'
import Happyfeetpinguin from './pingus/Happyfeetpinguin.png'
import Linuxpinguin from './pingus/Linuxpinguin.png'
import Nootnootpinguin from './pingus/Nootnootpinguin.png'
import YouveTriedPinguin from './pingus/YouveTriedPinguin.png'

import DerSchönstePinguin from './pingus/DerSchönstePinguin.png'


const DAYS = [17, 9, 10, 11, 24, 21, 3, 22, 7, 8, 5, 19, 1, 16, 4, 15, 12, 13, 6, 18, 23, 20, 14, 2]
const BACKGROUNDS = [door0, door1, door2, door3, door4, door5, door6, door7]
const DATA = [

  { day: 1, img: Brillenpinguin, description: 'Brillenpinguin' },
  { day: 2, img: Magellanpinguin, description: 'Magellanpinguin' },
  { day: 3, img: Tristanpinguin, description: 'Tristanpinguin' },
  { day: 4, img: Haubepinguin, description: 'Haubepinguin' },
  { day: 7, img: Zwergpinguin, description: 'Zwergpinguin' },
  { day: 8, img: Königspinguin, description: 'Königspinguin' },
  { day: 9, img: Galapagospinguin, description: 'Galapagospinguin' },
  { day: 10, img: Zügelpinguin, description: 'Zügelpinguin' },
  { day: 11, img: Goldschopfpinguin, description: 'Goldschopfpinguin' },
  { day: 13, img: Kronenpinguin, description: 'Kronenpinguin' },
  { day: 15, img: Gelbaugenpinguin, description: 'Gelbaugenpinguin' },
  { day: 16, img: Humboldtpinguin, description: 'Humboldtpinguin' },
  { day: 18, img: Felsenpinguin, description: 'Felsenpinguin' },
  { day: 20, img: Snaresinselpinguin, description: 'Snaresinselpinguin' },
  { day: 21, img: Dickschnabelpinguin, description: 'Dickschnabelpinguin' },
  { day: 22, img: Eselspinguin, description: 'Eselspinguin' },
  { day: 23, img: Kaiserpinguin, description: 'Kaiserpinguin' },
  // { day: -1, img: Adeliepinguin, description: 'Adeliepinguin' },

  { day: 5, img: Analysispinguin, description: 'Kowalski, Analysis!-Pinguin' },
  { day: 14, img: Unicodepinguin, description: 'Unicode-Pinguin' },
  { day: 6, img: Happyfeetpinguin, description: 'Happy Feet-Pinguin' },
  { day: 17, img: Linuxpinguin, description: 'Der-Pinguin-Namens-Tux-Pinguin' },
  { day: 12, img: Nootnootpinguin, description: 'Noot! Noot!-Pinguin' },
  { day: 19, img: YouveTriedPinguin, description: 'YouveTried-Pinguin' },

  { day: 24, img: DerSchönstePinguin, description: 'Der Schönste Pinguin <3' },
]

function dayAllowed(day: number) {
  let today = new Date();
  if (today.getMonth() < 11) return false;
  if (today.getMonth() === 0) return true;
  return today.getDate() >= day;
}

function dayOpened(day: number) {
  return localStorage.getItem(`day-${day}`) !== null
}

function openDay(day: number) {
  localStorage.setItem(`day-${day}`, 'yep')
}

function getState(): { [key: number]: 'LOCKED' | 'CLOSED' | 'OPEN' } {
  let res: { [key: number]: 'LOCKED' | 'CLOSED' | 'OPEN' } = {}
  for (let day of DAYS) {
    res[day] = 'LOCKED'
    if (dayAllowed(day))
      res[day] = 'CLOSED'
    if (dayOpened(day))
      res[day] = 'OPEN'
  }
  return res
}

interface IEntry {
  day: number,
  update: () => void,
  state: 'LOCKED' | 'CLOSED' | 'OPEN'
}

function Entry({ day, state, update }: IEntry) {
  let style = {
    width: '100%', height: '100%',
    display: "flex", justifyContent: "center", alignItems: "center",
    backgroundImage: `url(${BACKGROUNDS[day % BACKGROUNDS.length]})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    textShadow: '#000 0px 0px 2px',
  }
  if (state === 'OPEN') {
    (style as any)['backgroundImage'] = undefined
  }
  let entry = DATA.find(entry => entry.day === day)!
  return <span key={day} style={{ width: '5em', height: '7em', margin: '.5em' }}>
    <div style={style} onClick={() => {
      if (state === 'OPEN') return;
      if (!dayAllowed(day))
        alert("Möööp, zu Früh!")
      else {
        openDay(day)
      }
      update()
    }}>
      {state === 'OPEN' ? (
        <ModalImage
          small={entry.img}
          large={entry.img}
          hideZoom
          hideDownload
          alt={entry.description}
        />
      ) : null}
      {state === 'CLOSED' || state === 'LOCKED' ? (
        <>
          {/*Pingu <br />*/}
          #{day}
        </>
      ) : null}
    </div>
  </span>
}

function App() {
  let [state, setState] = React.useState(getState())
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ maxWidth: '48em' }}>
          <span style={{ display: 'flex', flexWrap: 'wrap' }}>
            {DAYS.map((day, i) => <Entry
              key={i}
              day={day}
              update={() => setState(getState())}
              state={state[day]}
            />)}
          </span>
        </div>
      </header>
    </div>
  );
}

export default App;
